import { useCallback, useMemo, useState } from "react";
import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
  CheckCircleIcon,
  CodeBracketSquareIcon,
  DocumentCheckIcon,
  ExclamationTriangleIcon,
  HandRaisedIcon,
  InboxArrowDownIcon,
  PaperAirplaneIcon,
  PresentationChartLineIcon,
  SquaresPlusIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { Characteristic, Tooltip } from "@sablier/v2-components/molecules";
import {
  STREAMS_PAGE_SIZE,
  StreamCategory,
  StreamShape,
} from "@sablier/v2-constants";
import {
  useChainData,
  useChainExplorer,
  useResolvedTypedAddress,
  useShortAddress,
} from "@sablier/v2-hooks";
import { useResolvedENS } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { policy } from "@sablier/v2-machines";
import { _ } from "@sablier/v2-mixins";
import { Stream } from "@sablier/v2-models";
import { useStreamWarning, useToken } from "~/client/hooks";
import type {
  ArrayItem,
  IAddress,
  IAlias,
  IChainDisplay,
  IMilliseconds,
  ITiming,
  ITokenDisplay,
  IValue,
} from "@sablier/v2-types";
import type { ComponentProps } from "react";
import type { IExtensionDependencies } from "~/client/types";

type Description = ComponentProps<typeof Characteristic>["description"];

interface BatchProps {
  label: string;
  size?: number;
}

export function Batch({ label, size }: BatchProps) {
  const { t } = useT();
  const description = useMemo((): Description => {
    const content = `${t("words.group")} #${label}`.concat(
      size ? ` (${size} ${_.capitalize(t("words.streams"))})` : "",
    );

    return [
      {
        purpose: "label",
        options: {
          icon: SquaresPlusIcon,
          value: t("characteristic.linkTo"),
        },
      },
      {
        purpose: "content",
        options: {
          value: content,
        },
      },
    ];
  }, [label, size, t]);

  return <Characteristic description={description} />;
}

interface SenderProps {
  chainId: number;
  address: string;
  proxy?: string;
}

export function Sender({ address, chainId, proxy }: SenderProps) {
  const { t } = useT();
  const { data: ens } = useResolvedENS({
    address,
    chainId,
  });

  const shortAddress = useShortAddress(address);
  const shortProxy = useShortAddress(proxy);

  const description = useMemo((): Description => {
    const builder: Description = [];
    builder.push(
      {
        purpose: "label",
        options: {
          icon: UserIcon,
          value: `${_.capitalize(t("words.sender"))}:`,
        },
      },
      {
        purpose: "copy",
        options: {
          value: shortAddress,
          toCopy: address,
        },
      },
    );

    if (ens) {
      builder.push({
        purpose: "content",
        options: {
          value: `(${ens})`,
        },
      });
    }

    if (!_.isNilOrEmptyString(proxy)) {
      builder.push(
        {
          purpose: "content",
          options: {
            value: t("characteristic.throughProxy"),
          },
        },
        {
          purpose: "copy",
          options: {
            value: shortProxy,
            toCopy: proxy,
          },
        },
      );
    }

    return builder;
  }, [address, ens, proxy, shortAddress, shortProxy, t]);

  return <Characteristic description={description} />;
}

interface RecipientProps {
  chainId: number;
  address: string;
}

export function Recipient({ address, chainId }: RecipientProps) {
  const { t } = useT();
  const { data: ens } = useResolvedENS({
    address,
    chainId,
  });

  const shortAddress = useShortAddress(address);

  const description = useMemo((): Description => {
    const builder: Description = [];
    builder.push(
      {
        purpose: "label",
        options: {
          icon: UserIcon,
          value: `${_.capitalize(t("words.recipient"))}:`,
        },
      },
      {
        purpose: "copy",
        options: {
          value: shortAddress,
          toCopy: address,
        },
      },
    );

    if (ens) {
      builder.push({
        purpose: "content",
        options: {
          value: `(${ens})`,
        },
      });
    }

    return builder;
  }, [address, ens, shortAddress, t]);

  return <Characteristic description={description} />;
}

interface ShapeProps {
  category: StreamCategory | undefined;
  shape: StreamShape | undefined;
}

export function Shape({ category, shape }: ShapeProps) {
  const { t } = useT();
  const description = useMemo((): Description => {
    const resolved = Stream.findShape(shape);
    const value = resolved.isUnknown ? category : t(resolved.title);

    return [
      {
        purpose: "label",
        options: {
          icon: PresentationChartLineIcon,
          value: `${t("words.shape")}:`,
        },
      },
      {
        purpose: "content",
        options: {
          value: _.capitalize(value),
        },
      },
    ];
  }, [category, shape, t]);

  return <Characteristic description={description} />;
}

interface DepositProps {
  amount: string | undefined;
  token: ITokenDisplay | undefined;
}

export function Deposit({ amount, token }: DepositProps) {
  const { t } = useT();

  const description = useMemo((): Description => {
    return [
      {
        purpose: "label",
        options: {
          icon: PresentationChartLineIcon,
          value: `${_.capitalize(t("words.deposit"))}:`,
        },
      },
      {
        purpose: "amount",
        options: {
          token,
          value: amount,
          isFormatted: true,
        },
      },
    ];
  }, [amount, token, t]);

  return <Characteristic description={description} />;
}

interface RateProps {
  amount: string | undefined;
  duration: IMilliseconds | undefined;
  token: ITokenDisplay | undefined;
}

export function Rate({ amount, duration, token }: RateProps) {
  const { t } = useT();

  const rates = useMemo(
    () => Stream.findRate(amount, duration),
    [amount, duration],
  );
  const options = useMemo(
    () => Object.keys(rates) as (keyof typeof rates)[],
    [rates],
  );
  const [index, setIndex] = useState<number>(0);

  const rate = useMemo(() => {
    const key = options[index];

    return rates[key];
  }, [index, options, rates]);

  const doLeft = useCallback(() => {
    if (index <= 0) {
      setIndex(options.length - 1);
    } else {
      setIndex(index - 1);
    }
  }, [index, setIndex, options]);

  const doRight = useCallback(() => {
    if (index >= options.length - 1) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  }, [index, setIndex, options]);

  const description = useMemo((): Description => {
    return [
      {
        purpose: "label",
        options: {
          icon: CodeBracketSquareIcon,
          value: `${_.capitalize(t("words.rate"))}:`,
        },
      },
      {
        purpose: "content",
        options: {
          value: `${token?.symbol} ${_.toNumeralPrice(rate?.toString())}`,
        },
      },
      {
        purpose: "content",
        options: {
          value: t("words.every"),
        },
      },
      {
        purpose: "button",
        options: {
          icon: ArrowLeftCircleIcon,
          onClick: doLeft,
        },
      },
      {
        purpose: "content",
        options: {
          value: _.get(options, index),
        },
      },
      {
        purpose: "button",
        options: {
          icon: ArrowRightCircleIcon,
          onClick: doRight,
        },
      },
    ];
  }, [token, t, doLeft, doRight, options, index, rate]);

  return <Characteristic description={description} />;
}

interface CancelableProps {
  is?: boolean;
  was?: boolean;
}

export function Cancelable({ was = false, is = false }: CancelableProps) {
  const { t } = useT();
  const description = useMemo((): Description => {
    return [
      {
        purpose: "label",
        options: {
          icon: HandRaisedIcon,
          value: `${t("words.cancelability")}:`,
        },
      },
      {
        purpose: "content",
        options: {
          value: is
            ? t("characteristic.cancelableHistorical")
            : was
            ? t("characteristic.cancelable")
            : t("characteristic.nonCancelable"),
        },
      },
    ];
  }, [is, t, was]);

  return <Characteristic description={description} />;
}

interface ContractProps {
  chainId: number | undefined;
  tokenId: string | number;
  address: string;
  version: string;
}

export function Contract({
  chainId,
  tokenId,
  address,
  version,
}: ContractProps) {
  const { t } = useT();

  const lockup = useChainExplorer({
    chainId,
    type: "address",
    hash: address,
  });

  const nft = useChainExplorer({
    chainId,
    type: "token",
    hash: address,
    tokenId,
  });

  const description = useMemo((): Description => {
    return [
      {
        purpose: "label",
        options: {
          icon: DocumentCheckIcon,
          value: `${t("words.contract")}:`,
        },
      },
      {
        purpose: "content",
        options: {
          value: t("characteristic.viewLockup"),
        },
      },
      {
        purpose: "external",
        options: {
          value: "contract",
          to: lockup,
        },
      },
      {
        purpose: "content",
        options: {
          value: t("structs.orThe"),
        },
      },
      {
        purpose: "external",
        options: {
          value: _.toUpper(t("words.nft")),
          to: nft,
        },
      },
      {
        purpose: "content",
        options: {
          value: `(${t("structs.version", { version })})`,
        },
      },
    ];
  }, [lockup, nft, t, version]);

  return <Characteristic description={description} />;
}

interface SummaryGroupProps {
  cancelability?: boolean;
  stream?: ArrayItem<IExtensionDependencies<"group">["streams"]>;
  timing?: ITiming;
}

export function Summary({ cancelability, stream, timing }: SummaryGroupProps) {
  const { t } = useT();

  const description = useMemo((): Description => {
    const builder: Description = [];

    if (
      _.isNil(cancelability) ||
      _.isNilOrEmptyString(timing) ||
      _.isNil(stream)
    ) {
      return builder;
    }

    builder.push(
      {
        purpose: "content",
        options: {
          value: cancelability
            ? t("characteristic.cancelableStream")
            : t("characteristic.nonCancelableStream"),
        },
      },
      {
        purpose: "content",
        options: {
          value: t("words.for"),
        },
      },
      {
        purpose: "copy",
        options: {
          value: _.toShortAddress(stream.address),
          toCopy: stream.address,
        },
      },
      ...(timing === "duration"
        ? [
            {
              purpose: "content" as const,
              options: {
                value: `${t("structs.withDurationOf")} ${
                  _.toDuration(stream.duration, "time-estimate")[0]
                }`,
              },
            },
            {
              purpose: "content" as const,
              options: {
                value: t("characteristic.startSoon"),
              },
            },
          ]
        : [
            {
              purpose: "content" as const,
              options: {
                value: `${t("words.from")} ${
                  _.toDuration(stream.start, "date")[0]
                }`,
              },
            },
            {
              purpose: "content" as const,
              options: {
                value: `${t("words.to")} ${
                  _.toDuration(stream.end, "date")[0]
                }`,
              },
            },
          ]),
      {
        purpose: "content",
        options: {
          value: `.`,
        },
      },
    );

    return builder;
  }, [cancelability, timing, stream, t]);

  return <Characteristic description={description} />;
}

interface GroupResultProps {
  amount: string;
  token?: ITokenDisplay;
  recipients?: number;
}

export function GroupResult({
  amount,
  token,
  recipients = 0,
}: GroupResultProps) {
  const { t } = useT();
  const description = useMemo((): Description => {
    return [
      {
        purpose: "content",
        options: {
          value: t("words.streaming"),
        },
      },
      {
        purpose: "amount",
        options: {
          token,
          value: amount,
        },
      },
      {
        purpose: "content",
        options: {
          value: `${t("words.to")} ${recipients} ${t("words.recipients")}`,
        },
      },
    ];
  }, [amount, token, recipients, t]);

  return <Characteristic description={description} />;
}

interface ExpectedProps {
  chain?: IChainDisplay;
  owner?: string;
}

export function Expected({ chain, owner }: ExpectedProps) {
  const { preview } = useResolvedTypedAddress(owner, chain?.chainId);
  const { t } = useT();

  const description = useMemo((): Description => {
    const list: Description = [
      {
        purpose: "label",
        options: {
          color: "gray200",
          icon: InboxArrowDownIcon,
          value: `${t("words.watching")}:`,
        },
      },
      {
        purpose: "content",
        options: {
          value: _.capitalize(t("words.streams")),
        },
      },
      {
        purpose: "content",
        options: {
          value: `${t("structs.startedBy")} `,
        },
      },
      {
        purpose: "content",
        options: {
          value: `${preview} (${t("words.you")}) ${t("words.on")}`,
        },
      },
      {
        purpose: "chain",
        options: {
          image: chain?.image,
          name: chain?.name,
        },
      },
    ];

    return list;
  }, [chain, preview, t]);

  return <Characteristic description={description} />;
}

interface FilterStreamProps {
  chain: IChainDisplay | undefined;
  sender: IAddress | undefined;
  recipient: IAddress | undefined;
  token: ITokenDisplay | undefined;
  ids: number | undefined;
  found: number | undefined;
}

export function FilterStream({
  sender,
  recipient,
  chain,
  token,
  ids,
  found = 0,
}: FilterStreamProps) {
  const { t } = useT();
  const s = useResolvedTypedAddress(sender, chain?.chainId);
  const r = useResolvedTypedAddress(recipient, chain?.chainId);

  const description = useMemo((): Description => {
    const prefix =
      found === 1
        ? t("characteristic.listingFirst")
        : found >= STREAMS_PAGE_SIZE
        ? t("characteristic.listingFirsts", { count: found })
        : `${t("characteristic.listingResults")}`;

    const list: Description = [
      {
        purpose: "content",
        options: {
          value: prefix,
        },
      },
      {
        purpose: "chain",
        options: {
          image: chain?.image,
          name: chain?.name,
        },
      },
      {
        purpose: "content",
        options: {
          value: t("words.streams"),
        },
      },
    ];

    if (!_.isNilOrEmptyString(token)) {
      list.push(
        {
          purpose: "content",
          options: {
            value: t("words.with"),
          },
        },
        {
          purpose: "amount",
          options: {
            isValueHidden: true,
            token,
          },
        },
      );
    }

    if (!_.isNilOrEmptyString(s.address) && _.isEthereumAddress(s.address)) {
      list.push(
        {
          purpose: "content",
          options: {
            value: t("words.involving"),
          },
        },
        {
          purpose: "copy-address",
          options: {
            isIconShown: false,
            preview: s.preview,
            toCopy: s.address,
            value: s.address,
          },
        },
      );
    }

    if (!_.isNilOrEmptyString(r.address) && _.isEthereumAddress(r.address)) {
      if (!_.isNilOrEmptyString(s.address) && _.isEthereumAddress(s.address)) {
        list.push({
          purpose: "content",
          options: {
            value: t("words.and"),
          },
        });
      } else {
        list.push({
          purpose: "content",
          options: {
            value: t("words.involving"),
          },
        });
      }

      list.push({
        purpose: "copy-address",
        options: {
          isIconShown: false,
          preview: r.preview,
          toCopy: r.address,
          value: r.address,
        },
      });
    }

    if (!_.isNilOrEmptyString(ids) && ids > 0) {
      list.push({
        purpose: "content",
        options: {
          value: t("characteristic.matchingStreamIds", { count: ids }),
        },
      });
    }
    return list;
  }, [chain, found, ids, r, s, t, token]);

  return <Characteristic description={description} />;
}

interface SimulatedProps {
  amount?: string;
  token?: ITokenDisplay;
}

export function Simulated({ amount, token }: SimulatedProps) {
  const { t } = useT();
  const description = useMemo((): Description => {
    return [
      {
        purpose: "content",
        options: {
          value: t("characteristic.outOfTotal"),
        },
      },
      {
        purpose: "amount",
        options: {
          token,
          value: amount,
          isFormatted: true,
        },
      },
    ];
  }, [amount, token, t]);

  return <Characteristic description={description} />;
}

interface InstanceProps {
  stream: Stream | undefined;
  purpose?: Parameters<typeof useStreamWarning>["0"];
  warning: string | undefined;
  value?: IValue;
}

export function Instance({
  stream,
  purpose = "cancel",
  warning: external,
  value,
}: InstanceProps) {
  const { t } = useT();

  const warnings = useMemo(
    () => ({
      canceled: policy.stream.itemCanceled(t),
      chain: policy.stream.itemUnchained(t),
      connect: policy.stream.itemWhitelist(t),
      early: policy.stream.itemEarly(t),
      ended: policy.stream.itemEnded(t),
      whitelist: policy.stream.itemWhitelist(t),
    }),
    [t],
  );

  const from = stream?.proxied ? stream?.proxender : stream?.sender;
  const to = stream?.recipient;

  const shortFrom = useShortAddress(from, 4, -3);
  const shortTo = useShortAddress(to, 4, -3);

  const token = useToken({ token: stream?.token });

  const { state } = useStreamWarning(purpose, stream);

  const warning = useMemo(
    () => _.get(warnings, state) || external,
    [external, state, warnings],
  );

  const description = useMemo((): Description => {
    const builder: Description = [];
    const isWarned = !_.isNilOrEmptyString(warning);

    const identifier = !_.isNilOrEmptyString(stream?.alias)
      ? _.toAlias(stream?.alias)
      : _.toShort(stream?.id, 8, -8);

    builder.push(
      {
        purpose: "label",
        options: {
          color: isWarned ? "yellow" : undefined,
          icon: isWarned ? ExclamationTriangleIcon : CheckCircleIcon,
          value: `${identifier}:`,
        },
      },
      {
        purpose: "content",
        options: {
          value: shortFrom,
        },
      },
      {
        purpose: "content",
        options: {
          value: `${t("words.to")} ${shortTo},`,
        },
      },
    );

    if (purpose === "withdraw") {
      builder.push(
        {
          purpose: "content",
          options: {
            value: t("words.withdraw"),
          },
        },
        {
          purpose: "amount",
          options: {
            token,
            value: _.toNumeralPrice(value?.humanized || "0"),
            isFormatted: true,
          },
        },
      );
    } else if (purpose === "cancel") {
      builder.push(
        {
          purpose: "content",
          options: {
            value: t("words.of"),
          },
        },
        {
          purpose: "amount",
          options: {
            token,
            value: _.toNumeralPrice(stream?.depositAmount.humanized),
            isFormatted: true,
          },
        },
      );
    }

    return builder;
  }, [shortFrom, shortTo, stream, token, warning, purpose, value, t]);

  if (!_.isNilOrEmptyString(warning)) {
    return (
      <Tooltip
        value={`Warning: ${warning}`}
        placement={"topLeft"}
        mouseEnterDelay={0.1}
        mouseLeaveDelay={0}
      >
        <div>
          <Characteristic description={description} />
        </div>
      </Tooltip>
    );
  }

  return <Characteristic description={description} />;
}

interface TransferableProps {
  is?: boolean;
}

export function Transferable({ is = true }: TransferableProps) {
  const { t } = useT();
  const description = useMemo((): Description => {
    return [
      {
        purpose: "label",
        options: {
          icon: PaperAirplaneIcon,
          value: `${t("words.transferability")}:`,
        },
      },
      {
        purpose: "content",
        options: {
          value: is
            ? t("characteristic.transferable")
            : t("characteristic.nonTransferable"),
        },
      },
    ];
  }, [is, t]);

  return <Characteristic description={description} />;
}

interface StreamCartChainsProps {
  chains: { id: number; name: string; count: number }[];
}

export function StreamCartChains({ chains }: StreamCartChainsProps) {
  const { t } = useT();
  const { find } = useChainData();

  const description = useMemo((): Description => {
    return [
      {
        purpose: "content",
        options: {
          value: t("characteristic.cart.chains.selected", {
            count: chains.length,
          }),
        },
      },
      ...chains.map((chain, index) => {
        const found = find(chain.id);
        const comma = chains.length > index + 1 ? "," : "";
        return {
          purpose: "chain" as const,
          options: {
            image: found?.image,
            name: `${chain.name || found?.name} (${chain.count})${comma}`,
          },
        };
      }),
    ];
  }, [chains, find, t]);

  return <Characteristic description={description} />;
}

interface StreamCartVersionsProps {
  versions: { id: IAlias; contract: IAddress; count: number }[];
}

export function StreamCartVersions({ versions }: StreamCartVersionsProps) {
  const { t } = useT();

  const description = useMemo((): Description => {
    return [
      {
        purpose: "content",
        options: {
          value: t("characteristic.cart.versions.selected", {
            count: versions.reduce((p, v) => p + v.count, 0),
          }),
        },
      },
      ...versions.map((version, index) => {
        const comma = versions.length > index + 1 ? "," : "";
        return {
          purpose: "copy-address" as const,
          options: {
            color: "white" as const,
            preview: `${version.id.toUpperCase()} (${version.count})${comma}`,
            toCopy: version.contract,
            value: version.contract,
          },
        };
      }),
    ];
  }, [t, versions]);

  return <Characteristic description={description} />;
}

interface StreamCartRecipientsProps {
  recipients: { id: IAddress; count: number }[];
}

export function StreamCartRecipients({
  recipients,
}: StreamCartRecipientsProps) {
  const { t } = useT();

  const description = useMemo((): Description => {
    return [
      {
        purpose: "content",
        options: {
          value: t("characteristic.cart.recipients.selected", {
            count: recipients.length,
          }),
        },
      },
      ...recipients.map((recipient) => {
        return {
          purpose: "copy-address" as const,
          options: {
            color: "white" as const,
            preview: `${_.toShortAddress(recipient.id)}(${recipient.count})`,
            toCopy: recipient.id,
            value: recipient.id,
          },
        };
      }),
    ];
  }, [t, recipients]);

  return <Characteristic description={description} />;
}

interface SelectedStreamProps {
  stream: Stream | undefined;
}

export function SelectedStream({ stream }: SelectedStreamProps) {
  const { t } = useT();

  const from = stream?.proxied ? stream?.proxender : stream?.sender;
  const to = stream?.recipient;

  const shortFrom = useShortAddress(from, 4, -3);
  const shortTo = useShortAddress(to, 4, -3);

  const { chain } = useChainData(stream?.chainId);
  const token = useToken({ token: stream?.token });

  const description = useMemo((): Description => {
    const builder: Description = [];

    const identifier = !_.isNilOrEmptyString(stream?.alias)
      ? _.toAlias(stream?.alias)
      : _.toShort(stream?.id, 8, -8);

    builder.push(
      {
        purpose: "select",
        options: {
          value: true,
          isIconOnly: true,
        },
      },
      {
        purpose: "label",
        options: {
          value: `${identifier}:`,
        },
      },
      {
        purpose: "content",
        options: {
          value: shortFrom,
        },
      },
      {
        purpose: "content",
        options: {
          value: `${t("words.to")} ${shortTo}`,
        },
      },
    );

    builder.push(
      {
        purpose: "content",
        options: {
          value: t("words.on"),
        },
      },
      {
        purpose: "chain",
        options: {
          image: chain?.image,
          name: chain?.name,
          isNameShown: false,
        },
      },
      {
        purpose: "content",
        options: {
          value: t("words.of"),
        },
      },
      {
        purpose: "amount",
        options: {
          token,
          value: _.toNumeralPrice(stream?.depositAmount.humanized),
          isFormatted: true,
        },
      },
    );

    return builder;
  }, [chain, shortFrom, shortTo, stream, token, t]);

  return <Characteristic description={description} />;
}

interface StreamCartCurrentChainProps {
  chainId: number | undefined;
}

export function StreamCartCurrentChain({
  chainId,
}: StreamCartCurrentChainProps) {
  const { t } = useT();
  const { chain } = useChainData(chainId);

  const description = useMemo((): Description => {
    return [
      {
        purpose: "content",
        options: {
          value: t("characteristic.cart.chains.current.connected"),
        },
      },
      {
        purpose: "chain",
        options: {
          image: chain?.image,
          name: chain?.name,
        },
      },
    ];
  }, [chain, t]);

  return <Characteristic description={description} />;
}
