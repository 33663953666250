import { StreamCategory, StreamVersion, links } from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { _ } from "@sablier/v2-mixins";
import { IStream } from "@sablier/v2-models";
import { vendors } from "@sablier/v2-utils";
import { gasser } from "~/client/utils";
import type { Event, Output } from "@sablier/v2-contracts";
import type {
  IAddress,
  IRole,
  ISigner,
  ITransactionReceipt,
  IWagmiConfig,
} from "@sablier/v2-types";
import type { Abi } from "viem";

interface ExtractForStream {
  receipt: ITransactionReceipt;
  purpose: StreamCategory;
}

export async function extractStreamIds({
  receipt,
  purpose,
}: ExtractForStream): Promise<string[] | undefined> {
  try {
    const name: Event<typeof purpose> = (() => {
      switch (purpose) {
        case StreamCategory.LOCKUP_LINEAR:
          return "CreateLockupLinearStream";
        case StreamCategory.LOCKUP_TRANCHED:
          return "CreateLockupTranchedStream";
        default:
          return "CreateLockupDynamicStream";
      }
    })();

    const abis = (await import("@sablier/v2-contracts/abis")).default;
    const abi = abis[purpose] as Abi;

    return framework.delog({
      abi,
      log: name,
      receipt,
      search: "streamId",
    });
  } catch (error) {
    vendors.crash.log(error);
  }
  return undefined;
}

export async function extractStreamId({
  receipt,
  purpose,
}: ExtractForStream): Promise<string | undefined> {
  const ids = await extractStreamIds({ receipt, purpose });

  if (ids && ids.length) {
    return ids[0];
  }

  return undefined;
}

interface ExtractForAirstream {
  receipt: ITransactionReceipt;
  category: StreamCategory;
}

export async function extractAirstreamIds({
  receipt,
  category,
}: ExtractForAirstream): Promise<string[] | undefined> {
  try {
    if (category === StreamCategory.LOCKUP_DYNAMIC) {
      return undefined;
    }

    const [name, key]: [Event<"merkleLockupFactory">, string] = (() => {
      switch (category) {
        case StreamCategory.LOCKUP_LINEAR:
          return ["CreateMerkleLL", "merkleLL"];
        case StreamCategory.LOCKUP_TRANCHED:
        default:
          return ["CreateMerkleLT", "merkleLT"];
      }
    })();

    const abis = (await import("@sablier/v2-contracts/abis")).default;
    const abi = abis["merkleLockupFactory"] as Abi;

    return framework.delog({
      abi,
      log: name,
      receipt,
      search: key,
    });
  } catch (error) {
    vendors.crash.log(error);
  }
  return undefined;
}

export async function extractAirstreamId({
  receipt,
  category,
}: ExtractForAirstream): Promise<string | undefined> {
  const ids = await extractAirstreamIds({ receipt, category });

  if (ids && ids.length) {
    return ids[0];
  }

  return undefined;
}

interface Configuration {
  chainId: number;
  query: Parameters<typeof framework.fuel>["1"]["query"];
  signer: Parameters<typeof framework.fuel>["1"]["signer"];
}

async function configure(
  library: IWagmiConfig,
  { chainId, query, signer }: Configuration,
) {
  const fallback = gasser(chainId, query.purpose, query.method);
  return framework.fuel(library, {
    query,
    signer,
    fallback: fallback.min,
    maxGas: fallback.max,
  });
}

interface Identify {
  address: IAddress | undefined;
  proxy?: IAddress | undefined;
  stream: IStream;
}

function identify({ address, proxy, stream }: Identify): IRole[] {
  const proxied = stream.version === StreamVersion.V20 ? stream.proxied : false;

  const result: IRole[] = [];

  if (proxied && _.toAddress(stream.sender) === _.toAddress(proxy)) {
    result.push("sender-proxy");
  } else if (!proxied && _.toAddress(stream.sender) === _.toAddress(address)) {
    result.push("sender-native");
  }

  if (_.toAddress(stream.recipient) === _.toAddress(address)) {
    result.push("recipient");
  }

  if (!_.isNilOrEmptyString(address) && result.length === 0) {
    return ["public"];
  }

  return result;
}

interface IsMultisig {
  safe: IAddress | undefined;
  chainId: number | undefined;
  library: IWagmiConfig;
}

export async function isMultisig({
  chainId,
  safe,
  library,
}: IsMultisig): Promise<boolean> {
  try {
    if (_.isNilOrEmptyString(safe) || _.isNilOrEmptyString(chainId)) {
      return false;
    }

    const query = framework.contextualize(
      safe!,
      chainId,
      "multisig",
      "getOwners",
      [],
    );

    const previews = await framework.preview({ queries: [query] });
    const results = await framework.read(library, { previews });

    const output = results[0].result as Output<"multisig", "getOwners">;

    return output.length > 0;
  } catch (error) {
    return false;
  }
}

type Debug =
  | {
      address: string;
      calldata: string;
      chainId: number;
      query?: never;
      sender: string | undefined;
    }
  | {
      address?: never;
      calldata?: never;
      chainId?: never;
      query: ReturnType<typeof framework.contextualize> | undefined;
      signer: ISigner | undefined;
    };

async function debug(options: Debug, isBenign = false) {
  let params: URLSearchParams | undefined = undefined;

  try {
    if (typeof options.address === "string") {
      const { address, calldata, chainId, sender } = options;

      params = new URLSearchParams({
        rawFunctionInput: calldata,
        contractAddress: address,
        network: _.toString(chainId),
        from: sender || "",
        value: "0",
      });
    } else {
      const { query, signer } = options;

      if (_.isNilOrEmptyString(query)) {
        throw new Error("Missing query, don't trigger debugging.");
      }

      const calldata = await framework.encodeQuery(query);
      const sender = signer?.account!.address;

      params = new URLSearchParams({
        rawFunctionInput: calldata,
        contractAddress: query.address,
        network: _.toString(query.chainId),
        from: sender || "",
        value: "0",
      });
    }

    const link = new URL(links.tools.tenderly.dashboard(params.toString()));

    const error = new Error(
      `[Sablier] Was this an unexpected error? Share the following link in the support chat to help us debug: ${link}`,
    );

    isBenign ? console.error(error) : vendors.crash.log(error);

    return link;
  } catch (_error) {
    //
  }

  return undefined;
}

type ISystem = "create" | "simulation" | "calldata";

const helper = {
  debug,
  configure,
  extractAirstreamId,
  extractAirstreamIds,
  extractStreamId,
  extractStreamIds,
  identify,
  isMultisig,
};

export type { ISystem };
export default helper;
