import { DEFAULT_RESET_SLEEP } from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { guards, policy } from "@sablier/v2-machines";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import { zeroAddress } from "viem";
import { isHostSafe } from "~/client/contexts/Web3";
import { toast } from "~/client/hooks/useToast";
import { peripheral } from "~/client/utils";
import type { Output } from "@sablier/v2-contracts";
import type { useMachineForm } from "@sablier/v2-hooks";
import type { Translate } from "@sablier/v2-locales";
import type { IStream } from "@sablier/v2-models";
import type {
  IAddress,
  ISigner,
  IWagmiAddress,
  IWagmiConfig,
} from "@sablier/v2-types";
import type { IForm } from "~/client/contexts/Form/Stream/Withdraw";
import type { useModalTransaction } from "~/client/hooks/modals";
import helper from "../helper";
import { withdraw as wording } from "../helper/wording";

export interface Check {
  fields: IForm;
  api: {
    t: Translate;
  };
}
export interface Create extends Check {
  fields: IForm;
  signer: ISigner | undefined;
  stream: IStream;
  library: IWagmiConfig | undefined;
  proxy: IAddress | undefined;
  api: {
    reset: () => void;
    t: Translate;
    setOpen: ReturnType<typeof useModalTransaction>["setOpen"];
    updateData: ReturnType<typeof useModalTransaction>["updateData"];
  };
}

export interface Result {
  message?: string;
}

export interface Preprocess {
  max: string;
}

type Machine = Parameters<
  typeof useMachineForm<Check, Create, Result, Preprocess>
>;

type onCheck = Parameters<Machine["0"]["onCheck"]>["0"];
type onProcess = Parameters<Machine["0"]["onProcess"]>["0"];
type onValidate = Parameters<Machine["0"]["onValidate"]>["0"];

export async function onCheck({ event }: onCheck): Promise<void> {
  const { ...fields } = event.payload.fields;
  const { t } = event.payload.api;

  const flags = guards.validateFormFlags({
    t,
    isLoadingIncluded: true,
    isWarningIncluded: true,
    value: fields,
  });

  if (!_.isNilOrEmptyString(flags)) {
    throw new Error(flags);
  }

  const ids: (keyof typeof fields)[] = ["amount", "max", "token"];

  if (fields.address.isActive) {
    ids.push("address");
  }

  const required = guards.validateFormRequired({
    t,
    required: ids,
    value: fields,
  });

  if (!_.isNilOrEmptyString(required)) {
    throw new Error(required);
  }
}

export async function onValidate({ context }: onValidate): Promise<Preprocess> {
  const { api, fields, library, proxy, signer, stream } = context.payload;
  const { t } = api;

  api.setOpen(true, {
    status: "verify",
    title: wording.title(t),
    description: wording.confirm(t).description,
    isNotClosable: true,
  });

  try {
    await onCheck({ event: context });

    if (_.isNil(signer) || _.isNil(library)) {
      throw new Error(policy.signer.missing(t));
    }

    const address = _.toAddress(signer.account?.address);
    const chainId = signer.chain!.id;
    const roles = helper.identify({ address, proxy, stream });
    const to = fields.address.resolution?.address;

    const requested = _.toValue({
      humanized: fields.amount.value,
      decimals: stream.token.decimals,
    });

    const isRedirected =
      fields.address.isActive && _.isEthereumAddress(fields.address.value);

    const recipient = (() => {
      /** If an operator (e.g. sender) tries to withdraw, they can only to it towards the recipient */
      if (!roles.includes("recipient")) {
        return stream.recipient;
      } else {
        /** For the recipient, they can withdraw anywhere they wish (specified `to` or default stream sender) */
        if (isRedirected) {
          return to;
        }
        return stream.recipient;
      }
    })();

    const results = await guards.validateInputs(
      library,
      t,
      [
        {
          purpose: "signer",
          options: {
            expected: roles.includes("recipient")
              ? [stream.recipient]
              : roles.includes("public")
              ? [address] /** StreamVersion.V22 enabled public withdrawals */
              : [stream.sender, stream.proxender || zeroAddress],
            chainId: stream.chainId,
            value: signer,
          },
        },
        {
          purpose: "recipient",
          options: {
            blacklist: [_.toAddress(zeroAddress)],
            value: recipient,
          },
        },
        {
          purpose: "screening",
          options: {
            chainId,
            addresses: [signer.account!.address, recipient ?? ""],
          },
        },
        ...(isRedirected
          ? [
              {
                purpose: "resolution" as const,
                options: {
                  value: fields.address.value,
                  resolved: to,
                },
              },
            ]
          : []),
        {
          purpose: "withdrawable",
          options: {
            contract: stream.contract,
            id: stream.tokenId,
            purpose: stream.category,
            requested: requested.humanized.toString(),
            token: stream.token,
          },
        },
      ],
      chainId,
      { toast },
    );

    /**
     * ------------------------------
     * Prepare outputs
     * ------------------------------
     */

    type O = Output<typeof stream.category, "withdrawableAmountOf">;
    const output = results["withdrawable"] as O;
    const withdrawable = _.toValuePrepared({
      decimals: stream.token.decimals,
      raw: new BigNumber(output.toString()),
    });

    return {
      max: withdrawable,
    };
  } catch (error) {
    vendors.crash.log(error);
    api.updateData({
      status: "fail",
      description: wording.fail(t).description,
      error: {
        message: _.toString(error),
        data: error,
      },
      isNotClosable: false,
    });
    throw error;
  }
}

/**
 *  Machine state that actually triggers the transaction.
 *  It relies on defined, pre-validated values checked within the `onValidate` step.
 */

export async function onProcess({ context }: onProcess): Promise<void> {
  const { api, fields, library, proxy, stream, signer } = context.payload;
  const { t } = api;
  let query = undefined;
  try {
    if (_.isNil(signer) || _.isNil(library)) {
      throw new Error(policy.signer.missing(t));
    }

    const address = _.toAddress(signer.account!.address);
    const chainId = signer.chain!.id;
    const lockup = stream.contract;
    const token = stream.token;
    const to = fields.address.resolution?.address;

    const target = peripheral(chainId, "targetApprove").address;
    const roles = helper.identify({ address, proxy, stream });

    const isRedirected =
      fields.address.isActive && _.isEthereumAddress(fields.address.value);

    const recipient = (() => {
      /** If an operator (e.g. sender) tries to withdraw, they can only to it towards the recipient */
      if (!roles.includes("recipient")) {
        return stream.recipient;
      } else {
        /** For the recipient, they can withdraw anywhere they wish (specified `to` or default stream sender) */
        if (isRedirected) {
          return to;
        }
        return stream.recipient;
      }
    })();

    const requested = _.toValuePrepared({
      humanized: fields.amount.value,
      decimals: stream.token.decimals,
    });

    const value = BigNumber.min(requested, context.preprocess.max);
    const preview = wording.prepare(token, value);

    query = await (async () => {
      if (roles.includes("sender-proxy")) {
        const data = framework.contextualize(
          target,
          chainId,
          "targetApprove",
          "withdraw",
          [
            lockup as IWagmiAddress,
            _.toBigInt(stream.tokenId),
            recipient as IWagmiAddress,
            _.toBigInt(
              _.toValuePrepared({
                raw: value,
                decimals: stream.token.decimals,
              }),
            ),
          ],
        );

        const calldata = await framework.encode(
          "targetApprove",
          "withdraw",
          data.inputs,
        );

        return framework.contextualize(proxy!, chainId!, "proxy", "execute", [
          target as IWagmiAddress,
          calldata,
        ]);
      } else if (
        roles.includes("sender-native") ||
        roles.includes("recipient") ||
        roles.includes("public")
      ) {
        return framework.contextualize(
          lockup,
          chainId,
          stream.category,
          "withdraw",
          [
            _.toBigInt(stream.tokenId),
            recipient as IWagmiAddress,
            _.toBigInt(
              _.toValuePrepared({
                raw: value,
                decimals: stream.token.decimals,
              }),
            ),
          ],
        );
      }
      throw new Error(policy.error.unidentified(t));
    })();

    api.updateData({
      status: "confirm",
      description: wording.send(
        t,
        true,
        _.toShortAddress(recipient),
        preview.amount,
      ).description,
      isNotClosable: true,
    });

    const prepared = await helper.configure(library, {
      chainId,
      query,
      signer,
    });

    console.info("%c[pre-transaction]", "color: mediumslateblue", {
      query,
      prepared,
    });

    const transaction = await framework.write(library, { prepared });

    api.updateData({
      status: "pending",
      description: wording.send(
        t,
        false,
        _.toShortAddress(recipient),
        preview.amount,
      ).description,
      hash: !isHostSafe ? transaction : undefined,
      isNotClosable: false,
    });

    const receipt = isHostSafe
      ? await framework.safeWait(library, { hash: transaction })
      : await framework.wait(library, {
          hash: transaction,
          onReplaced: (replaced) => {
            api.updateData({
              hash: replaced.transaction.hash,
            });
          },
        });

    console.info("%c[post-transaction]", "color: mediumslateblue", {
      transaction,
      receipt,
    });

    if (receipt.status === "reverted") {
      throw new Error(policy.error.reverted(t));
    }

    await _.sleep(DEFAULT_RESET_SLEEP);

    api.updateData({
      status: "success",
      description: wording.success(
        t,
        _.toShortAddress(recipient),
        preview.amount,
      ).description,
      hash: receipt.transactionHash,
      isNotClosable: false,
    });

    api.reset();
  } catch (error) {
    void helper.debug(
      {
        query,
        signer,
      },
      vendors.crash.log(error),
    );

    api.updateData({
      status: "fail",
      description: wording.fail(t).description,
      error: {
        message: policy.error.message(t, error),
        data: error,
      },
      isNotClosable: false,
    });

    throw error;
  }
}
