import { ComponentProps, useMemo } from "react";
import { CheckIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import Table from "@sablier/v2-components/organisms/Table";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { getTheme } from "@sablier/v2-themes";
import extensions, { useExtensionUtilities } from "~/client/extensions";
import type { ISTableCell } from "@sablier/v2-components/organisms/Table";
import { IUnlock } from "../extensions/tranched/Backweighted/config";
import { useStreamCreateGroupField } from "./store";

const theme = getTheme();

type Cells = [
  ISTableCell["Label"],
  ISTableCell["Label"],
  ISTableCell["Text"],
  ISTableCell["Amount"],
  ISTableCell["TextDeck"],
  ...ISTableCell["Text"][],
];

type Data = ComponentProps<typeof Table>["data"];

export default function useStreamPreviewCSVTable(isFull = false): Data {
  const streams = useStreamCreateGroupField("streams");
  const isDuration = useStreamCreateGroupField("isDuration");
  const token = useStreamCreateGroupField("token");
  const shape = useStreamCreateGroupField("shape");
  const extend = useExtensionUtilities();
  const { t } = useT();

  const { extension } = useMemo(
    () =>
      extend.initialize({
        shape: shape.field.value,
      }),
    [extend, shape],
  );

  const extended = useMemo(
    () =>
      extensions.headers({
        purpose: extension?.purpose,
        isDuration: isDuration.field.value,
      }),
    [extension?.purpose, isDuration?.field.value],
  );

  const columns = useMemo(() => {
    const base = [
      {
        id: "1",
        layout: "Label",
        title: "#",
        weight: "var(--dashboard-column-index)",
      },
      {
        id: "2",
        layout: "Label",
        title: "Validity",
        weight: "var(--dashboard-column-valid)",
      },
      {
        id: "3",
        layout: "Text",
        title: _.capitalize(t("words.recipient")),
        weight: isFull
          ? "var(--dashboard-column-address)"
          : "var(--dashboard-column-address-small)",
      },
      {
        id: "4",
        layout: "Amount",
        title: _.capitalize(t("words.amount")),
        weight: "var(--dashboard-column-amount)",
      },
    ];

    if (isDuration.field.value) {
      base.push({
        id: "5",
        layout: "Text",
        title: t("words.duration"),
        weight: "var(--dashboard-column-duration)",
      });
    } else {
      base.push(
        {
          id: "5-1",
          layout: "Text",
          title: t("words.start"),
          weight: "var(--dashboard-column-date)",
        },
        {
          id: "5-2",
          layout: "Text",
          title: t("words.end"),
          weight: "var(--dashboard-column-date)",
        },
      );
    }

    if (isFull) {
      base.push(
        ...extended.map((e, i) => ({
          id: (i + 6).toString(),
          layout: "Text",
          title: _.startCase(e.header),
          weight: `var(--dashboard-column-${i + 6})`,
        })),
      );
    } else if (extended.length) {
      base.push({
        id: "6",
        layout: "Text",
        title: _.startCase(t("words.other")),
        weight: "var(--dashboard-column-more)",
      });
    }

    return base;
  }, [extended, isDuration, isFull, t]);

  const rows = useMemo(() => {
    return streams.field.value.map((row, index) => {
      let warning = [token.field, row.address, row.amount].find(
        (item) => !_.isNilOrEmptyString(item.warning),
      )?.warning;

      if (_.isNilOrEmptyString(warning)) {
        warning = isDuration.field.value
          ? row.duration.warning
          : !_.isNilOrEmptyString(row.start.warning)
          ? `Start date: ${row.start.warning}`
          : !_.isNilOrEmptyString(row.end.warning)
          ? `End date: ${row.end.warning}`
          : undefined;
      }

      const extensionWarnings = extensions.warnings(row.extension);
      if (_.isNilOrEmptyString(warning)) {
        if (extensionWarnings?.length) {
          warning = extensionWarnings[0];
        }
      }
      const cells = [
        {
          value: {
            value: `${index + 1}.`,
          },
        },
        {
          value: _.isNilOrEmptyString(warning)
            ? {
                isIconLast: true,
                value: "Valid",
                icon: CheckIcon,
              }
            : {
                isIconLast: true,
                value: "Invalid",
                color: "yellow",
                icon: ExclamationCircleIcon,
                tooltip: {
                  value: warning,
                },
              },
        },
        {
          value: _.toShortAddress(
            row.address.value,
            isFull ? 14 : 8,
            isFull ? -12 : -4,
          ),
        },
        {
          value: {
            token: token.field.value,
            value: row.amount.value,
          },
        },
      ] satisfies Partial<Cells>;

      if (isDuration.field.value) {
        cells.push({
          value: _.toDuration(row.duration.value, "time-full")[0],
        });
      } else {
        cells.push(
          {
            value: _.toDuration(row.start.value, "date-short")[0],
          },
          {
            value: _.toDuration(row.end.value, "date-short")[0],
          },
        );
      }

      if (isFull) {
        cells.push(
          ...extended.map((e) => ({
            value: (() => {
              const value = _.get(_.get(row.extension, e.header), "value");

              switch (e.type) {
                case "date":
                  return _.toDuration(value, "date-short")[0];
                case "duration":
                  return _.toDuration(value, "time-full")[0];
                case "percentages":
                  return (value as unknown as IUnlock[]).reduce(
                    (accumulator, current, index) => {
                      const unlock = `Year${current.year}: ${current.percentage}%`;
                      return index === 0 ? unlock : `${accumulator},${unlock}`;
                    },
                    "",
                  );
                case "integer":
                default:
                  return _.toString(value);
              }
            })(),
          })),
        );
      } else if (extended.length) {
        cells.push({
          value: `+${extended.length} ${t("words.more")}`,
        } satisfies ISTableCell["Text"]);
      }

      return {
        id: row.address.value! + `${index}`,
        cells,
      };
    });
  }, [extended, isDuration, isFull, streams, token, t]);

  return {
    columns,
    rows,
    instructions: {},
    isAnimated: false,
    isLoading: false,
    isEmpty: rows.length === 0,
    options: {
      row: theme.sizes.tableRowSlim,
    },
  };
}
