import { MAX_GROUP_STREAM_SIZE, StreamShapes } from "@sablier/v2-constants";
import {
  FormProvider,
  useFormContext,
  useFormField,
  useFormStore,
} from "@sablier/v2-contexts";
import { _ } from "@sablier/v2-mixins";
import type { StreamShape } from "@sablier/v2-constants";
import type { IMilliseconds, IStoreSelector, IToken } from "@sablier/v2-types";
import type { PropsWithChildren } from "react";
import type { IExtension } from "~/client/extensions";

export interface IStream<T = IExtension> {
  id: string;
  address: {
    id: string;
    isFocused: boolean;
    isLoading: boolean;
    resolution?: {
      address?: string;
      ens?: string;
      preview?: string;
      short?: string;
    };
    value?: string;
    warning?: string;
  };
  amount: {
    id: string;
    isLoading: boolean;
    value?: string;
    warning?: string;
  };
  duration: {
    id: string;
    isActive: boolean;
    value?: IMilliseconds;
    warning?: string;
  };
  start: {
    id: string;
    value?: IMilliseconds;
    warning?: string;
  };
  end: {
    id: string;
    value?: IMilliseconds;
    warning?: string;
  };
  max: {
    id: string;
    value?: string;
  };
  extension: T;
}

function createStream(
  id: string,
  extension: IExtension = { purpose: undefined },
): IStream {
  return {
    id,
    address: {
      id: `address`,
      isFocused: false,
      isLoading: false,
      resolution: undefined,
      value: undefined,
      warning: undefined,
    },
    amount: {
      id: `amount`,
      isLoading: false,
      value: undefined,
      warning: undefined,
    },
    duration: {
      id: `duration`,
      isActive: true,
      value: undefined,
      warning: undefined,
    },
    end: {
      id: `end`,
      value: undefined,
      warning: undefined,
    },
    max: {
      id: `max`,
      value: undefined,
    },
    start: {
      id: `start`,
      value: undefined,
      warning: undefined,
    },
    extension,
  };
}

export interface IForm<T = IExtension> {
  cancelability: {
    id: string;
    value: boolean;
    warning?: string;
  };
  isDuration: {
    id: string;
    value: boolean;
  };
  transferability: {
    id: string;
    value: boolean;
    warning?: string;
  };
  shape: {
    id: string;
    isLoading: boolean;
    value: StreamShape | string;
  };
  token: {
    id: string;
    value?: IToken;
    warning?: string;
  };
  max: {
    id: string;
    value?: string;
  };
  file: {
    id: string;
    isLoading: boolean;
    /** Pagination for the recipients preview table */
    preview: number;
    /** Status of the underlying file-to-backend interaction */
    status: "missing" | "uploaded" | "successful" | "failed";
    value: File | undefined;
    warning: string | undefined;
  };
  streams: {
    id: string;
    value: IStream<T>[];
  };
}

const initial: IForm = {
  cancelability: {
    id: "cancelability",
    value: true,
    warning: undefined,
  },
  isDuration: {
    id: "isDuration",
    value: true,
  },
  transferability: {
    id: "transferability",
    value: true,
    warning: undefined,
  },
  shape: {
    id: "shape",
    isLoading: true,
    value: StreamShapes.linear.id,
  },
  token: {
    id: "token",
    value: undefined,
    warning: undefined,
  },
  max: {
    id: "max",
    value: undefined,
  },
  streams: {
    id: "streams",
    value: [createStream("0")],
  },
  file: {
    id: "file",
    isLoading: false,
    preview: MAX_GROUP_STREAM_SIZE,
    status: "missing",
    value: undefined,
    warning: undefined,
  },
};

function Provider({ children }: PropsWithChildren<unknown>) {
  return <FormProvider<IForm> initial={initial}>{children}</FormProvider>;
}

function useAccessor<T = IExtension>() {
  return useFormContext<IForm<T>>().getState;
}

function useForm<Slice>(selector: IStoreSelector<IForm, Slice>): Slice {
  return useFormStore(selector);
}

function useField<I extends keyof IForm>(id: I) {
  return useFormField<IForm, I>(id);
}

function useFactory() {
  return { createStream };
}

export { useAccessor, useField, useForm, useFactory };

export default Provider;
